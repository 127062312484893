import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s-1.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">In our work we have pride, quality is what we provide.</h3>
                                            <p>At BNC, we are more than just a construction company; we are builders of dreams and architects of
                                                 progress.         we have firmly established ourselves as a leading force in the construction
                                                  and pre-engineered building industry. 
                                                  Our unwavering commitment to quality, safety,
                                                   and client satisfaction drives us to continually redefine the possibilities in the world of construction.</p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span></span>
                                                    <h4>Connect With Us</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"#"} className="site-button-secondry btn-effect">8263-835-149</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;