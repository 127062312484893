import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0">We are all constructed out of our self dialogue.</h3>
                                <p>At BNC, our dedicated team is the heart of our success.
                                     We are a diverse group of professionals united by a shared passion for building excellence.
                                     Together, we turn visions into reality, Multiple projects at a time.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/1.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>Design Excellence</h4>
                                            <p>At BCN, we excel in crafting 
                                                innovative designs that form the blueprint
                                                 for remarkable structures.</p>
                                            <NavLink to="/project-detail"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/2.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>Industrial Experts</h4>
                                            <p>Our Expertiese in Industrial Logistic Parks are Unshaken for the past 8 years making us pioneers in this field.</p>
                                            <NavLink to="/project-detail"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/3.jpg')} alt="" />
                                        <div className="figcaption bg-dark">
                                            <h4>Master Contractors</h4>
                                            <p>As experienced Master Contractors, we bring visions to life, delivering projects that stand as testimoney of our quality and precision.</p>
                                            <NavLink to="/project-detail"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization3;