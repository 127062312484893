import React from 'react';
import { NavLink } from 'react-router-dom';

class Faq extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
                    <div className="container">
                        {/* FAQ START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one ">
                                                    <span className="font-weight-300 text-primary">Frequently Asked</span> Questions
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="section-content p-b30">
                                        <div className="mt-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel mt-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            What services does BNC Construction offer?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse in">
                                                    <div className="acod-content p-tb15">
                                                        <p>
                                                            BNC Construction offers a wide range of services, including building construction, renovation, maintenance, interior design, professional consultation, accurate engineering, electrical solutions, and refurbishment.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            How can I contact BNC Construction?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">
                                                        <p>
                                                            You can reach out to BNC Construction through our Contact Us page on our website. Our team will promptly respond to your inquiries and provide you with the necessary information.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                            What sets BNC Construction apart from others in the industry?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">
                                                        <p>
                                                            BNC Construction distinguishes itself through its commitment to excellence, precision engineering, creative interior design, and comprehensive services. We prioritize customer satisfaction and ensure every project meets the highest standards.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Add more FAQs and answers here */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one ">
                                                    <span className="font-weight-300 text-primary">Our</span> Solution
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-gray center">
                                                <div className="mt-icon-box-sm inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-sketch" /></span>
                                                </div>
                                                <div className="icon-content text-black">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Assistance</h4>
                                                    <p>
                                                        At BNC Construction, we offer expert assistance for all your construction needs. Our team of professionals is dedicated to providing guidance and support throughout your project. We ensure that your vision becomes a reality, from planning to execution.
                                                    </p>
                                                    <NavLink to="/about" className="site-button-link" data-hover="Read More">
                                                        Read More <i className="fa fa-angle-right arrow-animation" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                                                <div className="mt-icon-box-sm inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-ui" /></span>
                                                </div>
                                                <div className="icon-content text-white">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Financing</h4>
                                                    <p>
                                                        BNC Construction offers financing solutions tailored to your construction projects. We understand that every project requires financial planning, and our experts can assist you in securing the necessary funds. Partner with us for your construction financing needs.
                                                    </p>
                                                    <NavLink to="/about" className="site-button-link yellow" data-hover="Read More">
                                                        Read More <i className="fa fa-angle-right arrow-animation" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FAQ */}
                    </div>
                </div>
            </>
        );


    }
};

export default Faq;