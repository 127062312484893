import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/4.jpg');

class Faq extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Frequently Asked Questions" pagename="FAQ" bgimage={bnrimg} />

                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1 bg-gray p-a50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-center">
                                        <div className="mt-separator">
                                            <h2 className="text-uppercase sep-line-one ">FAQ</h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* Accordion */}
                                <div className="mt-accordion acc-bg-white" id="accordion5">
                                    <div className="panel mt-panel">
                                        <div className="acod-head acc-actives">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                    How do we work?
                                                    <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne5" className="acod-body collapse in">
                                            <div className="acod-content p-tb15">
                                                <p>
                                                    BNC Construction works by progressively generating synergistic total linkage through cross-media intellectual capital. We enthusiastically parallel task team building for e-tailers without standards-compliant initiatives. Our team strives to monetize client-centric outsourcing with excellent communities. We prioritize excellence in every project, ensuring the highest standards are met.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                    What services do we offer?
                                                    <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p>
                                                    BNC Construction offers a wide range of services, including building construction, renovation, maintenance, interior design, professional consultation, accurate engineering, electrical solutions, and refurbishment. We specialize in delivering comprehensive solutions tailored to your construction needs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                    Why do you have a design fee and purchasing fee on the product?
                                                    <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p>
                                                    BNC Construction charges a design fee and purchasing fee to cover the cost of our expert design services and the procurement of materials and products for your project. These fees enable us to provide you with the best design solutions and source high-quality materials, ensuring the success of your project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                    How long will it take to design and build my project?
                                                    <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFour5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p>
                                                    The timeline for designing and building your project depends on its complexity and scope. Our team at BNC Construction will work closely with you to provide an estimated timeline during the planning phase. We prioritize efficient project management to ensure timely completion while maintaining the highest quality standards.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                    What should I have at our first meeting for my project?
                                                    <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p>
                                                    To make the most of our first meeting for your project, it's helpful to have any initial ideas, inspirations, or sketches you may have in mind. Additionally, providing details about your project's objectives, budget, and timeline will assist our team in better understanding your needs. We're here to collaborate and guide you through the process, turning your vision into a successful project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Add more FAQs and answers here */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );

    };
};

export default Faq;